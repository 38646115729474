import type { Dayjs } from 'dayjs';
// @ts-ignore
import UtilitiesAccountData = Domain.Utilities.DataObjects.UtilitiesAccountData;

export type FormId = number | undefined;

export type UtilitiesFilters = {
  from: string | null;
  search: string | null;
  status: string | null;
  to: string | null;
  ids: string[] | null;
};

export type UtilitiesQuery = {
  filter: UtilitiesFilters;
  page: number;
  perPage: number;
  sort: string;
};

export type UtilitiesStats = {
  draft: number;
  sent: number;
  pending: number;
  processing: number;
  approved: number;
  active: number;
  cancelled: number;
  declined: number;
  total: number;
};

export type LettingSeason = Domain.Utilities.DataObjects.LettingSeason & {};

export enum CreateEditOrderStep {
  'DETAILS' = 'details',
  'TENANTS' = 'tenants',
  'SUMMARY' = 'summary',
  'PREVIEW' = 'preview',
  'CONFIRMATION' = 'confirmation',
}

export enum CreateEditOrderMode {
  'CREATE' = 'create',
  'EDIT' = 'edit',
}

// App.Enums.LettingAgent.UtilityForms.FormStatus
export enum FormStatus {
  'ACTIVE' = 'Active',
  'AMENDED' = 'Amended/Unsent',
  'APPROVED' = 'Approved',
  'CANCELLED' = 'Cancelled',
  'CLOSED' = 'Closed',
  'DECLINED' = 'Declined',
  'DRAFT' = 'Draft',
  'PENDING' = 'Pending',
  'PROCESSING' = 'Processing',
  'SENT' = 'Sent',
  'VOID' = 'Void',
}

export type UtilitiesOrderStep = {
  id: CreateEditOrderStep;
  title: string;
  to: { name: string; params?: Record<string, string | number | null> };
  completed: boolean;
  disabled: boolean;
  updated?: boolean;
  last?: boolean;
  submit?: boolean;
  showFooter: boolean;
  showHeaderNav: boolean;
};

export type Property = {
  id: number;
  address: {
    number: string;
    street: string;
    postcode: string;
    city: string;
  };
  numberOfRooms: number;
  availableFrom: Dayjs | string;
  availableTo: Dayjs | string;
};

export type Tenant = Domain.Utilities.DataObjects.TenantData & {};

export type Services = {
  gas: boolean;
  electricity: boolean;
  internet: string;
  water: boolean;
  tvLicence: boolean;
};

export type GasSupplier = {
  id: number;
  name: string;
  url: string;
};
export type ElectricalSupplier = {
  id: number;
  name: string;
  url: string;
};

export type TariffData = {
  name: string;
};

export type Address = {
  line1: string | null;
  line2: string | null;
  line3: string | null;
  line4: string | null;
  line5: string | null;
  houseNo: string | null;
  houseName: string | null;
  subBuilding: string | null;
  province: string | null;
  street: string | null;
  city: string | null;
  postcode: string | null;
  country: string | null;
  countryCode: string | null;
  label: string | null;
};

export type UtilitiesForm = UtilitiesAccountData & {
  id: FormId;
  tenants: Tenant[];
  tenantType: string | null;
  property: Property;
  status: FormStatus | null;
  addressString: string;
  createdAtString: string;
  endDateString: string;
  startDateString: string;
  online: boolean;
  agentBilling: boolean;
  inactive: boolean;
  sent: boolean;
  tenantCount: number;
  signaturesRemaining: number;
  tenantsSigned: number;
  expand: boolean;
  overdue: boolean;
  isEditable: boolean;
  isVoidable: boolean;
  billingType?: string | null;
  electricalSupplier?: ElectricalSupplier | null;
  gasSupplier?: GasSupplier | null;
  showTariff: boolean;
  tariff: TariffData | null;
  recordTypeName?: string | null;
};

export type UtilitiesFormResponse = {
  data: {
    value: {
      message: string;
      hasErrors: boolean;
      results: {
        id: number | null;
        success: string | null;
        error: string | null;
        message: string | null;
      }[];
    };
  };
  error: {
    value: {
      data: {
        error: string;
      };
    };
  };
};
